<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-6 gap-5">
		<!-- Таблица -->
		<TableResultNok />
	</div>
</template>

<script>
import TableResultNok from './TableResultNok.vue';

export default {
	name: 'Main',
	components: { TableResultNok }
};
</script>
